const isValid = (value) => {
  if (value == "" || (value !== "" && value.match(
    new RegExp("[0-9]{2}\\/?[0-9]{7}-[0-9]{1}")) == null)) {
    return false;
  }
  return true;
};

export default {
  isValid
};